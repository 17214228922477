<template>
	<div class="webFooter" :class="themes=='white'?'white':''">
		<div class="footer-container">
			<img :src="appLogo" alt="logo" class="footer-logo">
			<p>Globalized digital asset trading platform © 2023 All rights reserved.</p>
		</div></div>
	</div>
</template>

<script>
	export default{
		props:{
			themes:{
				type:String,
				default:'dark',
			}
		},
		data(){
			return{
				appLogo:'',
				appName:'',
			}
		},
		created(){
			this.getLogo();
		},
		methods:{
			async getLogo() {
			  const { data } = await this.$http.get("/home/index/getConf");
			  if (data) {
			    if (data.code === 200) {
			      this.appLogo = data.data.imageurl;
			      this.appName = data.data.sitename;
			    }
			  }
			},
		}
	}
</script>

<style lang="less" scoped>
.webFooter{min-width: 1200px;padding: 33px 0 40px;background-color: #18191d; width: 100%;
	.footer-container{ width:1200px; margin: 0 auto; text-align: center;
		.footer-logo{ display: block;  height: 35px; margin: 5px auto;}
		p{color:#777e90;}
	}
	&.white{background-color: rgb(248, 248, 248);
	}
}
</style>